import React from 'react'
import { Button, Typography } from '@material-ui/core'
import Logo from '../../logo/testoil-logo.png'
import PaddedFlexCentered from 'components/PaddedFlexCentered'

interface Props {
  onConfirm: () => void
}
export const SignUpSubmitted = (props: Props) => {
  const {
    onConfirm,
  } = props
  return (
    <>
      <PaddedFlexCentered>
        <img src={Logo} alt="Eurofins TestOil Logo" style={{ width: "400px", paddingBottom: "50px" }} />
        <Typography component="h1" variant="h5">
          Almost Done...
        </Typography>
        <p>
          Thanks for submitting your request to use AssetLink.
        </p>

        <p>
          You will receive an email to complete your account registration once our Customer Engagement Team creates your account.
        </p>
        <p>
          You can contact our Customer Engagement Team at <a href="mailto:to.assetlinkinfo@et.eurofinsus.com">to.assetlinkinfo@et.eurofinsus.com</a> or by calling <a href="tel:1-216-251-2510"> (216) 251-2510</a> if you have any questions regarding the status of your registration request.
        </p>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          return
        </Button>
      </PaddedFlexCentered>
    </>

  )
}

export default SignUpSubmitted
