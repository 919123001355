import React from 'react'

import SignUp from 'components/SignUp/SignUp'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const SignUpPage = () => {
  return (
    <>
      <SiteHeadMetadata title="Sign Up" />
      <SignUp />
    </>
  )
}

export default SignUpPage
